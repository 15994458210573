import { InMemoryCache } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import moment from 'moment';
import sum from 'lodash/sum';
import countBy from 'lodash/countBy';
import {
  shoppingCartShow,
  shoppingCartOffersVar,
} from '../reactive-variables/shopping-cart';
import getObjectsFromCache from './getObjectsFromCache';
import managerPaginationCache from './managerPaginationCache';
import {
  addMoneyWithCurrency,
  subtractMoneyWithCurrency,
} from '../../helpers/money';
import { SUPPLIER_PLATFORM_TYPE } from '../../constants/credentials';
import graphqlIdGenerator from '../../helpers/graphql-id-generator';
import { FINGO_MASTER_ENTITY_ID } from '../../constants/master-entities';
import popUpRecoveryPasswordDemoUser from '../reactive-variables/user';
import { selectedCompanyRut } from '../reactive-variables/platform';
import {
  FirstLoadOfInvoices,
  preofferSelected,
} from '../reactive-variables/invoices';
import {
  orderingSelected,
  orderingInvoiceIssuedSelected,
  orderingOfferSelected,
} from '../reactive-variables/purchase-orders';
import { setLocalStorageVariable } from '../helpers/local-storage';

const MAX_FINANCING_DAYS_ALLOWED = 180;

export default () => new InMemoryCache({
  possibleTypes: {
    CompanyDocumentInterfaceType: [
      'TGRType',
      'CTEType',
      'BalanceType',
      'CompanyFrameworkContractType',
      'EERRType',
      'CompanyFrameworkContractRegisterType',
      'CompanyDocumentManagerType',
    ],
  },
  typePolicies: {
    possibleTypes: {
      CompanyDocumentInterfaceType: [
        'TGRType',
        'CTEType',
        'BalanceType',
        'EERRType',
        'CompanyFrameworkContractType',
        'CompanyDocumentManagerType',
      ],
    },
    Query: {
      fields: {
        invoiceMessages: relayStylePagination(['invoiceId', 'messageType']),
        mail: relayStylePagination(['emailName_Icontains', 'orderBy']),
        myCompanies: relayStylePagination([
          'orderBy',
          'masterEntity_Name_Icontains',
        ]),
        getPreoffers: managerPaginationCache(),
        purchaseOrders: managerPaginationCache(),
        getMasterEntities: managerPaginationCache(null, 'MasterEntityType'),
        invoices: managerPaginationCache(null, 'InvoiceType'),
        conciliationMovements: managerPaginationCache(
          null,
          'ConciliationMovementType',
        ),
        nonOperativeMovements: managerPaginationCache(
          null,
          'NonOperativeMovementObjectType',
        ),
        getLoanManagers: managerPaginationCache(),
        invoice: {
          read(existing, options) {
            if (existing) return existing;
            const objects = getObjectsFromCache(
              options.cache,
              'InvoiceType',
              [options.args.invoiceId],
            );
            return objects[0];
          },
        },
        whatsappLog: relayStylePagination(['conversationHash', 'orderBy']),
        selectedCompanyRut: {
          read() {
            return selectedCompanyRut();
          },
        },
        selectedPreofferIds: {
          read() {
            return preofferSelected();
          },
        },
        selectedOfferIds: {
          read() {
            return shoppingCartOffersVar().map((offer) => offer.id);
          },
        },
        selectedOfferDateToPay: {
          read() {
            return shoppingCartOffersVar().map((offer) => {
              const ret = {};
              ret[offer.id] = offer.dateToPay;
              return ret;
            });
          },
        },
        selectedOrderingIds: {
          read() {
            return orderingSelected();
          },
        },
        selectedOfferOrderingIds: {
          read() {
            return orderingOfferSelected();
          },
        },
        orderingInvoiceIssuedSelected: {
          read() {
            return orderingInvoiceIssuedSelected();
          },
        },
        showShoppingCart: {
          read() {
            return shoppingCartShow();
          },
        },
        firstLoad: {
          read() {
            return FirstLoadOfInvoices();
          },
        },
        popUpRecoveryPassword: {
          read() {
            return popUpRecoveryPasswordDemoUser;
          },
        },
      },
    },
    MarketingCampaignSummaryType: {
      keyFields: null,
    },
    InvoiceMonthlyRate: {
      keyFields: null,
    },
    Currency: {
      keyFields: null,
    },
    FileFieldType: {
      keyFields: null,
    },
    ConfirmingPayrollType: {
      fields: {
        createdAt: {
          read(existing) {
            return moment(existing);
          },
        },
        updatedAt: {
          read(existing) {
            return moment(existing);
          },
        },
        totalAmount: {
          read(_, { readField }) {
            return (
              sum(
                readField('operations').map((operation) => sum(
                  readField('confirmingSet', operation).map((confirming) => readField(
                    'amount',
                    readField('amountWithIva', confirming),
                  )),
                )),
              ) || 0
            );
          },
        },
        totalCount: {
          read(_, { readField }) {
            return (
              sum(
                readField('operations').map(
                  (operation) => readField('confirmingSet', operation).length,
                ),
              ) || 0
            );
          },
        },
        statusInfo: {
          read(_, { readField }) {
            const flatStatus = readField('operations').flatMap((operation) => readField('confirmingSet', operation).flatMap((confirming) => readField('status', confirming)));
            return countBy(flatStatus) || {};
          },
        },
        supplierCount: {
          read(_, { readField }) {
            return readField('operations').length || 0;
          },
        },
        companyName: {
          read(_, { readField }) {
            const flatName = readField('operations').flatMap((operation) => readField('confirmingSet', operation).map((confirming) => readField(
              'name',
              readField(
                'masterEntity',
                readField('receiver', confirming),
              ),
            )));
            return flatName[0] || '';
          },
        },
        companyBlacklist: {
          read(_, { readField }) {
            const flatName = readField('operations').flatMap((operation) => readField('confirmingSet', operation).map((confirming) => readField(
              'riskBlacklist',
              readField(
                'masterEntity',
                readField('receiver', confirming),
              ),
            )));
            return flatName[0] || null;
          },
        },
        areAllOperationsEqual: {
          read(_, { readField }) {
            const flatExpirationDates = new Set(
              readField('operations').flatMap((operation) => readField('expirationDate', operation).toISOString()),
            );
            const flatRates = new Set(
              readField('operations').flatMap((operation) => readField('monthlyRate', operation)),
            );
            return flatExpirationDates.size === 1 && flatRates.size === 1;
          },
        },
        companyRut: {
          read(_, { readField }) {
            const flatRut = readField('operations').flatMap((operation) => readField('confirmingSet', operation).map((confirming) => readField(
              'rut',
              readField(
                'masterEntity',
                readField('receiver', confirming),
              ),
            )));
            return flatRut[0] || '';
          },
        },
      },
    },
    ConfirmingOperationType: {
      fields: {
        expirationDate: {
          read(existing) {
            return moment(existing);
          },
        },
        supplierName: {
          read(_, { readField }) {
            return (
              readField('confirmingSet').map((confirming) => readField(
                'name',
                readField('masterEntity', readField('company', confirming)),
              ))[0] || ''
            );
          },
        },
        supplierRut: {
          read(_, { readField }) {
            return (
              readField('confirmingSet').map((confirming) => readField(
                'rut',
                readField('masterEntity', readField('company', confirming)),
              ))[0] || ''
            );
          },
        },
        totalAmount: {
          read(_, { readField }) {
            return (
              sum(
                readField('confirmingSet').map((confirming) => readField('amount', readField('amountWithIva', confirming))),
              ) || 0
            );
          },
        },
        totalCount: {
          read(_, { readField }) {
            return readField('confirmingSet').length || 0;
          },
        },
        statusInfo: {
          read(_, { readField }) {
            const flatStatus = readField('confirmingSet').flatMap(
              (confirming) => readField('status', confirming),
            );
            return countBy(flatStatus) || {};
          },
        },
      },
    },
    RatificationManagerType: {
      fields: {
        createdAt: {
          read(existing) {
            return moment(existing);
          },
        },
        assignedPriorityDatetime: {
          read(existing) {
            return moment(existing);
          },
        },
        updatedAt: {
          read(existing) {
            return moment(existing);
          },
        },
      },
    },
    InvoiceTypeConnection: {
      keyFields: null,
      fields: {
        flatInvoices: {
          read(_, { readField }) {
            return readField('edges').map((edge) => edge.node);
          },
        },
      },
    },
    ConfirmingAnalyticsType: {
      fields: {
        operation_ExpirationDate: {
          read(existing) {
            return moment(existing);
          },
        },
      },
    },
    CessionType: {
      fields: {
        cessionDate: {
          read(existing) {
            return moment(existing);
          },
        },
      },
    },
    MasterEntityType: {
      fields: {
        graphqlId: {
          read(_, { readField }) {
            const id = Number(readField('id'));
            const type = Number(readField('__typename'));
            return graphqlIdGenerator(type, id);
          },
        },
      },
    },
    CountryType: {
      fields: {
        graphqlId: {
          read(_, { readField }) {
            const id = Number(readField('id'));
            const type = Number(readField('__typename'));
            return graphqlIdGenerator(type, id);
          },
        },
      },
    },
    UserType: {
      fields: {
        completeName: {
          read(_, { readField }) {
            const firstName = readField('firstName');
            const lastName = readField('lastName');
            return `${firstName} ${lastName}`;
          },
        },
        graphqlId: {
          read(_, { readField }) {
            const id = Number(readField('id'));
            const type = Number(readField('__typename'));
            return graphqlIdGenerator(type, id);
          },
        },
        selectedCompany: {
          read(company, { readField }) {
            if (company) {
              setLocalStorageVariable(
                'selectedCompanyRut',
                readField('rut', company),
              );
            }
            return company;
          },
        },
        hasActivePhoneVerificationCode: {
          read(_, { readField }) {
            const phoneverification = readField('phoneverification');
            if (phoneverification) {
              const expirationDate = readField(
                'expirationDate',
                phoneverification,
              );
              return moment(expirationDate) > moment();
            }
            return false;
          },
        },
      },
    },
    CompanyType: {
      fields: {
        name: {
          read(serverName, { readField }) {
            if (serverName === '') return readField('rut');
            return serverName;
          },
        },
        bankAccounts: {
          read(bankAccounts, { readField }) {
            return [...bankAccounts].sort((a, b) => {
              const activeOne = readField('active', a);
              const activeTwo = readField('active', b);
              if (activeOne === activeTwo) {
                return readField('bankName', a) > readField('bankName', b);
              }
              if (activeOne) return -1;
              return 1;
            });
          },
        },
        negativeSurplusBalance: {
          read(_, { readField }) {
            const surplusBalance = readField('currentSurplusBalanceValue');
            if (surplusBalance.amount < 0) {
              return {
                amount: -surplusBalance.amount,
                currency: surplusBalance.currency,
              };
            }
            return {
              amount: 0,
              currency: surplusBalance.currency,
            };
          },
        },
        navBarTitles: {
          read(_, { readField }) {
            const groups = readField('groupModules');
            const permissions = new Set();
            groups.forEach((group) => {
              const groupPermissions = readField('permissions', group);
              groupPermissions.forEach((perm) => permissions.add(readField('code', perm)));
            });
            return permissions;
          },
        },
        siiCredentials: {
          read(_, { readField }) {
            const credentials = readField('validCredentials');
            if (!credentials) return [];
            return credentials.filter(
              (cred) => readField(
                'name',
                readField(
                  'credentialProviderType',
                  readField('credentialProvider', cred),
                ),
              ) === 'government',
            );
          },
        },
        invoiceProviderCredentials: {
          read(_, { readField }) {
            const credentials = readField('validCredentials');
            if (!credentials) return [];
            return credentials.filter(
              (cred) => readField(
                'name',
                readField(
                  'credentialProviderType',
                  readField('credentialProvider', cred),
                ),
              ) === 'electronic_invoices',
            );
          },
        },
        supplierPortalCredentials: {
          read(_, { readField }) {
            const credentials = readField('validCredentials');
            if (!credentials) return [];
            return credentials.filter(
              (cred) => readField(
                'name',
                readField(
                  'credentialProviderType',
                  readField('credentialProvider', cred),
                ),
              ) === SUPPLIER_PLATFORM_TYPE,
            );
          },
        },
        hasSiiCredentials: {
          read(_, { readField }) {
            const credentials = readField('siiCredentials');
            return credentials.length > 0;
          },
        },
        hasLendingAcceptedterms: {
          read(_, { readField }) {
            const terms = readField('currentUserAcceptedTerms');
            if (!terms) return false;
            const lendingTerms = terms.filter(
              (ref) => readField('product', ref) === 'LENDING',
            );
            return lendingTerms.length > 0;
          },
        },
        hasCollectionAcceptedTerms: {
          read(_, { readField }) {
            const terms = readField('currentUserAcceptedTerms');
            if (!terms) return false;
            const collectionTerms = terms.filter(
              (ref) => readField('product', ref) === 'COLLECTION',
            );
            return collectionTerms.length > 0;
          },
        },
        hasInvoiceProviderCredentials: {
          read(_, { readField }) {
            const credentials = readField('invoiceProviderCredentials');
            return credentials.length > 0;
          },
        },
        hasSupplierPortalCredentials: {
          read(_, { readField }) {
            const credentials = readField('supplierPortalCredentials');
            return credentials.length > 0;
          },
        },
      },
    },
    ConciliationMovementType: {
      fields: {
        totalAmount: {
          read(_, { readField }) {
            const debit = readField('debit');
            const credit = readField('credit');
            return addMoneyWithCurrency(debit, credit);
          },
        },
        movementDate: {
          read(movementString) {
            return movementString && moment(movementString);
          },
        },
      },
    },
    MarketingCampaignType: {
      fields: {
        createdAt: {
          read(stringDate) {
            return moment(stringDate);
          },
        },
        scheduledMailingDate: {
          read(stringDate) {
            return moment(stringDate);
          },
        },
      },
    },
    CompanyCreditLineType: {
      fields: {
        availableLine: {
          read(_, { readField }) {
            const totalAmount = readField('totalAmount');
            const creditUsed = readField('creditUsed');
            return totalAmount.amount - creditUsed.amount;
          },
        },
      },
    },
    OrderingPurchaseOrderType: {
      fields: {
        publicationDate: {
          read(publishDateString) {
            return moment(publishDateString);
          },
        },
        simulationSelectable: {
          read(_, { readField }) {
            const status = readField('status');
            if (status === 'EVALUATION') {
              return { selectable: false, status: 'Evaluación' };
            }
            if (status === 'SIMULATION') {
              return { selectable: true, status: 'Disponible' };
            }
            return { selectable: false, status: '-' };
          },
        },
        nonAvailableReason: {
          read(_, { readField }) {
            const status = readField('status');
            const publicationDate = readField('publicationDate');
            const totalAmount = readField('totalAmount');
            const currency = readField('currency');
            const diffDate = moment().startOf('day').diff(publicationDate, 'days') > 120;
            if (diffDate) {
              return 'Su orden de compra posee más de 120 días desde su fecha de emisión';
            }
            if (currency !== 'CLP') {
              return 'Su orden de compra no se encuentra en pesos chilenos';
            }
            if (totalAmount.amount < 60000) {
              return 'Su orden de compra posee un monto menor a $60.000';
            }
            if (totalAmount.amount > 1000000000) {
              return 'Su orden de compra posee un monto mayor a $100.000.000';
            }
            if (status === 'SIMULATION') {
              return '';
            }
            if (status === 'EVALUATION') {
              return 'Su orden de compra elegida ya fue enviada a evaluar, y nuestro equipo está trabajando para aceptar su factura';
            }
            if (status === 'OFFERED') {
              if (diffDate) {
                return 'Su orden de compra se le hizo oferta, pero ya pasaron más de 120 días desde su fecha de publicación';
              }
              return 'Su orden de compra posee oferta. Acéptela en la pantalla de ofertas de órdenes de compra';
            }
            if (status === 'REJECTED') {
              return 'Su solicitud de ordering fue rechazada por nuestro equipo';
            }
            const operatedStatus = [
              'PENDINGASSIGNMENTDOCUMENT',
              'PENDINGSIGNATURE',
              'PENDINGTRANSFER',
              'PENDINGCOLLECTION',
              'PENDINGINVOICES',
              'FACTORING',
              'FINISHED',
            ];
            if (operatedStatus.includes(status)) {
              return 'Su ordering ya fue operado. Revise su pestaña de operaciones activas para ver el estado de tu ordering';
            }
            return '';
          },
        },
      },
    },
    OrderingOfferType: {
      fields: {
        invoiceIssuedDate: {
          read(invoiceIssuedString) {
            return moment(invoiceIssuedString);
          },
        },
      },
    },
    DocumentFinanceStateType: {
      fields: {
        paymentDate: {
          read(paymentDate) {
            return paymentDate && moment(paymentDate);
          },
        },
      },
    },
    InvoiceType: {
      fields: {
        isFunding: {
          read(_, { readField }) {
            const manager = readField('invoiceloanmanager');
            if (!manager) {
              return {
                value: false,
                socialReason: '',
              };
            }
            const roster = readField('invoiceLoanRoster', manager);
            const fund = readField('fund', roster);
            const entity = readField('masterEntity', fund);
            const entityName = readField('name', entity);
            return {
              value: true,
              socialReason: entityName,
            };
          },
        },
        creditNotes: {
          read(_, { readField }) {
            const references = readField('references');
            if (!references) return [];
            return references.filter(
              (ref) => readField('code', readField('dteType', ref)) === '61',
            );
          },
        },
        debitNotes: {
          read(_, { readField }) {
            const references = readField('references');
            if (!references) return [];
            return references.filter(
              (ref) => readField('code', readField('dteType', ref)) === '56',
            );
          },
        },
        providerTrace: {
          read(_, { readField }) {
            const uniqueDocumentFinanceState = readField(
              'uniqueDocumentFinanceState',
            );
            if (!uniqueDocumentFinanceState) return [];
            return (
              uniqueDocumentFinanceState.filter(
                (ref) => readField('code', readField('status', ref)) !== '1',
              ) || []
            );
          },
        },
        providerRejectionReasons: {
          read(_, { readField }) {
            const uniqueDocumentFinanceState = readField(
              'uniqueDocumentFinanceState',
            );
            if (!uniqueDocumentFinanceState) return {};
            return (
              uniqueDocumentFinanceState.filter(
                (ref) => readField('code', readField('status', ref)) === '1',
              ) || []
            );
          },
        },
        companyRut: {
          read(_, { readField }) {
            const company = readField('company');
            const rut = readField('rut', company);
            return rut;
          },
        },
        dateIssued: {
          read(dateString) {
            return moment(dateString);
          },
        },
        dateExpiration: {
          read(dateString) {
            return moment(dateString);
          },
        },
        dateToPay: {
          read(dateString) {
            return moment(dateString);
          },
        },
        dateOperation: {
          read(dateString) {
            return moment(dateString);
          },
        },
        paymentMethod: {
          read(_, { readField }) {
            const invoicedetail = readField('invoicedetail');
            if (!invoicedetail) {
              const siiStatus = readField('siiStatus');
              if (siiStatus === 'ALREADY_PAYED') {
                return 'Contado';
              }
              return 'Crédito';
            }
            // In case there is no FmaPago in XML, default value for payment method is credit
            // https://www.sii.cl/factura_electronica/formato_dte.pdf
            const paymentMethod = readField('paymentMethod', invoicedetail) || 2;
            return { 1: 'Contado', 2: 'Crédito', 3: 'Sin Costo' }[
              paymentMethod
            ];
          },
        },
        receptionDate: {
          read(dateString, { readField }) {
            if (dateString) {
              return moment(dateString);
            }
            return readField('dateIssued');
          },
        },
        hasExecutiveMerit: {
          read(_, { readField }) {
            const daysToMerit = readField('daysToExecutiveMerit');
            return daysToMerit <= 0;
          },
        },
        daysToExecutiveMerit: {
          read(_, { readField }) {
            const receptionDate = readField('receptionDate');
            const receptionMoment = moment(receptionDate);
            return (
              9
                - moment()
                  .startOf('day')
                  .diff(receptionMoment.startOf('day'), 'days')
            );
          },
        },
        invoiceStatus: {
          read(_, { readField }) {
            const preoffer = readField('preoffer');
            const status = readField('status');
            if (!preoffer) {
              return status;
            }
            if (status === 'OFFERED') {
              return 'OFFER';
            }
            if (status === 'CREATED') {
              if (preoffer) {
                const evaluationRequest = readField(
                  'preofferevaluationrequest',
                  preoffer,
                );
                if (evaluationRequest) {
                  const evaluationStatus = readField(
                    'status',
                    evaluationRequest,
                  );
                  if (evaluationStatus === 'Rejected') {
                    return 'REJECTED';
                  }
                  if (evaluationStatus === 'Evaluating') {
                    return 'EVALUATING';
                  }
                }
                return 'PREOFFER';
              }
            }
            return status;
          },
        },
        preofferSelectable: {
          read(_, { readField }) {
            const siiStatus = readField('siiStatus');
            const loanedStatus = readField('loanedStatus');
            const loanedToRut = readField('loanedToRut');
            const creditNoteStatus = readField('creditNoteStatus');
            const dateIssued = readField('dateIssued');
            const preoffer = readField('preoffer');
            const offer = readField('offer');
            const operation = readField('operation');
            const isForOrdering = readField('isForOrdering');
            const hasPaymentsEmitted = readField('hasPaymentsEmitted');

            if (isForOrdering) {
              return { selectable: false, status: 'IS_FOR_ORDERING' };
            }
            if (operation) {
              return { selectable: false, status: 'HAS_OPERATION' };
            }
            if (['LOANED'].includes(loanedStatus)) {
              if (
                loanedToRut
                  && String(loanedToRut) !== selectedCompanyRut()
                  && String(loanedToRut) !== FINGO_MASTER_ENTITY_ID
              ) {
                return { selectable: false, status: loanedStatus };
              }
            }
            if (offer) {
              return { selectable: false, status: 'HAS_OFFER' };
            }
            if (
              preoffer
                && readField('preofferevaluationrequest', preoffer)
            ) {
              const evaluationRequest = readField(
                'preofferevaluationrequest',
                preoffer,
              );
              const evaluationStatus = readField('status', evaluationRequest);
              return { selectable: false, status: evaluationStatus };
            }
            if (['HAS_CREDIT_NOTE'].includes(creditNoteStatus)) {
              return { selectable: false, status: creditNoteStatus };
            }
            if (hasPaymentsEmitted) {
              return { selectable: false, status: 'HAS_PAYMENTS_EMITTED' };
            }
            if (
              [
                'REJECTED_BY_SII',
                'REJECTED_RECEIVER',
                'TOTAL_CLAIM',
                'ALREADY_PAYED',
                'REJECTED_FOR_CONTENT',
                'REJECTED_FOR_TOTAL',
                'REJECTED_FOR_PARTIAL',
              ].includes(siiStatus)
            ) {
              return { selectable: false, status: siiStatus };
            }
            if (dateIssued < moment().subtract(3, 'months')) {
              return { selectable: false, status: 'EXPIRED' };
            }
            if (!preoffer) {
              return { selectable: false, status: siiStatus };
            }
            return { selectable: true, status: siiStatus };
          },
        },
        amounts: {
          read(_, { readField }) {
            const offerRef = readField('offer');
            const retentionRate = offerRef
              ? readField('retentionRate', offerRef)
              : 0;
            const amountWithIva = readField('amountWithIva').amount;
            const monthlyRate = offerRef
              ? readField('monthlyRate', offerRef)
              : null;
            const dateExpiration = readField('dateExpiration');
            const startOfDay = moment().startOf('day');
            const daysUntilExpiration = dateExpiration.diff(
              startOfDay,
              'days',
            );
            const dateToPay = daysUntilExpiration > MAX_FINANCING_DAYS_ALLOWED
              ? startOfDay.clone().add(180, 'days')
              : dateExpiration.clone();
            const days = dateToPay.diff(moment().startOf('day'), 'days');
            const effectiveRate = (monthlyRate / 100) * (days / 30);
            const retentionAmount = parseInt(
              (retentionRate / 100) * amountWithIva,
              10,
            );
            const advanceAmount = amountWithIva - retentionAmount;
            const amountMinusInterests = parseInt(
              advanceAmount * (1 - effectiveRate),
              10,
            );
            const priceDifference = advanceAmount - amountMinusInterests;
            return {
              dateToPay: null,
              effectiveRate,
              retentionAmount,
              advanceAmount,
              amountMinusInterests,
              priceDifference,
            };
          },
        },
        lightningPaymentAuthorized: {
          read(_, { readField }) {
            const dateIssued = readField('dateIssued');
            const states = readField('uniqueDocumentFinanceState') || [];
            if (moment().diff(dateIssued, 'days') > 15) return false;
            if (!states.length) return false;
            const payedState = states.find((state) => {
              const status = readField('status', state);
              const code = readField('code', status);
              return code === '5';
            });
            return !!payedState;
          },
        },
      },
    },
    NonOperativeMovementObjectType: {
      fields: {
        createdAt: {
          read(createdAt) {
            return moment(createdAt);
          },
        },
        absoluteAmount: {
          read(_, { readField }) {
            const active = readField('active');
            const passive = readField('passive');
            const result = readField('result');
            const activeMinusPassive = subtractMoneyWithCurrency(
              active,
              passive,
            );
            const absoluteAmount = subtractMoneyWithCurrency(
              activeMinusPassive,
              result,
            );
            return absoluteAmount;
          },
        },
      },
    },
    NonOperativeMovementTypeType: {
      fields: {
        label: {
          read(_, { readField }) {
            return readField('name');
          },
        },
      },
    },
    ConciliatedMovementTypeType: {
      fields: {
        label: {
          read(_, { readField }) {
            return readField('name');
          },
        },
      },
    },
    CreditLineInfoType: {
      fields: {
        limitAmount: {
          read(_, { readField }) {
            const currentLimit = readField('currentLimit');
            if (!currentLimit) return 0;
            const newLimit = readField('newLimit', currentLimit);
            return newLimit.amount;
          },
        },
        lastUpdate: {
          read(_, { readField }) {
            const currentLimit = readField('currentLimit');
            if (!currentLimit) return '';
            const createdAt = readField('createdAt', currentLimit);
            return createdAt;
          },
        },
        creditUsed: {
          read(_, { readField }) {
            const lastMovement = readField('lastMovement');
            if (!lastMovement) return 0;
            const creditUsed = readField('totalCreditUsed', lastMovement);
            return creditUsed.amount;
          },
        },
      },
    },
    MailType: {
      fields: {
        label: {
          read(_, { readField }) {
            return readField('emailName');
          },
        },
        graphqlId: {
          read(_, { readField }) {
            const id = Number(readField('id'));
            const type = Number(readField('__typename'));
            return graphqlIdGenerator(type, id);
          },
        },
      },
    },
    AmortizationScheduleType: {
      fields: {
        createdAt: {
          read(stringDate) {
            return moment(stringDate);
          },
        },
      },
    },
    PendingDocumentsManagerType: {
      fields: {
        createdAt: {
          read(stringDate) {
            return moment(stringDate);
          },
        },
      },
    },
    InstallmentType: {
      fields: {
        expirationDate: {
          read(stringDate) {
            return moment(stringDate);
          },
        },
      },
    },
    InstallmentManagerType: {
      fields: {
        paymentDatetime: {
          read(stringDate) {
            if (!stringDate) return null;
            return moment(stringDate);
          },
        },
      },
    },
  },
});
