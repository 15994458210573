import { gql } from '@apollo/client';

const selectedCompanyQuery = gql`
  query selectedCompany {
    selectedCompany @client
  }
`;

const preofferSelectedQuery = gql`
  query preofferSelectedQuery {
    selectedPreofferIds @client
  }
`;

const offerSelectedQuery = gql`
  query offerSelectedQuery {
    selectedOfferIds @client
  }
`;

const offerDateToPaySelectedQuery = gql`
  query selecteOfferDateToPayQuery {
    selectedOfferDateToPay @client
  }
`;

const orderingSelectedQuery = gql`
  query selectedOrderingIds {
    selectedOrderingIds @client
  }
`;

const orderingInvoiceIssuedSelectedQuery = gql`
  query orderingInvoiceIssuedSelected {
    orderingInvoiceIssuedSelected @client
  }
`;

const orderingSimulationSelection = gql`
  query orderingSimulationSelection {
    orderingInvoiceIssuedSelected @client
    selectedOrderingIds @client
  }
`;

const orderingOfferSelection = gql`
  query orderingOfferSelection {
    selectedOfferOrderingIds @client
  }
`;

const allSelectionIds = gql`
  query allSelectionIds {
    selectedPreofferIds @client
    selectedOfferIds @client
    selectedOrderingIds @client
  }
`;

const shoppingCartShowQuery = gql`
  query shoppingCartShowQuery {
    showShoppingCart @client
  }
`;

const popUpRecoveryPasswordQuery = gql`
  query popUpRecoveryPasswordQuery {
    popUpRecoveryPassword @client
  }
`;

const FirstLoadOfInvoicesQuery = gql`
  query FirstLoadOfInvoicesQuery {
    firstLoad @client
  }
`;

export {
  selectedCompanyQuery,
  preofferSelectedQuery,
  offerSelectedQuery,
  offerDateToPaySelectedQuery,
  orderingSelectedQuery,
  orderingInvoiceIssuedSelectedQuery,
  orderingSimulationSelection,
  orderingOfferSelection,
  allSelectionIds,
  shoppingCartShowQuery,
  FirstLoadOfInvoicesQuery,
  popUpRecoveryPasswordQuery,
};
